<template>
  <div id='TitledToast' class="container">
    <span class="text-bold">{{ url == "/" ? '' : url }}</span>
    <p class="p-0 m-0">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "TitledToast",
  props: {
    url: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    }
  }
}
</script>