import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/LoginPage.vue";
import { languages } from "@/i18n";
import store from "@/store";
import axios from "@/axiosClient";
import { buildRequest, REQUESTS, apiAuth } from "@/components/Javascript/methods";

const childRoutes = [
  {
    path: "",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "DashBoard" */ "../views/DashBoard.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "patient/:patientId?",
    name: "History",
    component: () => import(/* webpackChunkName: "History" */ "../views/PatientHistory.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "account",
    name: "Account",
    component: () => import(/* webpackChunkName: "DashBoard" */ "../components/Settings/AccountSettings.vue"),
    meta: {
      requiresAuth: true,
      prescriberFriendly: true,
      externalFriendly: true,
    },
  },

  {
    path: "security",
    name: "Security",
    component: () => import(/* webpackChunkName: "DashBoard" */ "../components/Settings/SecuritySettings.vue"),
    meta: {
      requiresAuth: true,
      prescriberFriendly: true,
      externalFriendly: true,
    },
  },

  {
    path: "information",
    name: "Information",
    component: () => import(/* webpackChunkName: "DashBoard" */ "../components/Settings/InformationSettings.vue"),
    meta: {
      requiresAuth: true,
      prescriberFriendly: true,
      externalFriendly: true,
    },
  },

  {
    path: "import",
    name: "ImportFile",
    component: () => import(/* webpackChunkName: "ImportFile" */ "../views/ImportFile.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "scan",
    name: "Scanner Page",
    component: () => import(/* webpackChunkName: "ScannerPage" */ "../views/ScannerPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "form/records/:patientId?/:recordId?",
    name: "AddRecord",
    component: () => import(/* webpackChunkName: "RecordStepper" */ "../views/RecordStepper.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "form/external_records/:patientId?/:recordId?",
    name: "AddExternalRecord",
    component: () => import(/* webpackChunkName: "ExternalRecordStepper" */ "../views/ExternalRecordStepper.vue"),
    meta: {
      requiresAuth: true,
      externalFriendly: true,
    },
  },

  {
    path: "form/parameters/:id?",
    name: "AddEditParameters",
    component: () => import(/* webpackChunkName: "AddEditParameters" */ "../views/AddEditParameters.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "data-management",
    name: "DataManagement",
    component: () => import(/* webpackChunkName: "DataManagement" */ "../views/DataManagement.vue"),
    meta: {
      requiresAuth: true,
      // externalFriendly: true,
    },
  },

  {
    path: "uploads/:upload_record_id?",
    name: "Uploads",
    component: () => import(/* webpackChunkName: "Uploads" */ "../views/Uploads.vue"),
    meta: {
      requiresAuth: true,
      externalFriendly: true,
    },
  },

  {
    path: "verify-email/:verificationToken",
    name: "VerifyEmail",
    component: () => import(/* webpackChunkName: "DataManagement" */ "../views/LandingPage.vue"),
    props: { type: "email" },
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "verify-phone-number/:verificationToken",
    name: "VerifyPhone",
    component: () => import(/* webpackChunkName: "DataManagement" */ "../views/LandingPage.vue"),
    props: { type: "phone" },
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "validate-record/:recordId?",
    name: "ValidateRecord",
    component: () => import(/* webpackChunkName: "PreviewRecord" */ "../views/PreviewRecord.vue"),
    props: { operation: "validate" },
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "invalidate-record/:recordId?",
    name: "InvalidateRecord",
    component: () => import(/* webpackChunkName: "PreviewRecord" */ "../views/PreviewRecord.vue"),
    props: { operation: "invalidate" },
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "table/:table_name/:task_type?",
    name: "TablePage",
    component: () => import(/* webpackChunkName: "TablePage" */ "../views/TablePage.vue"),
    meta: {
      requiresAuth: true,
      externalFriendly: ["collectors", "prescribers"],
    },
  },

  {
    path: "table/:table_name",
    name: "PrescribersDashboard",
    component: () => import(/* webpackChunkName: "TablePage" */ "../views/TablePage.vue"),
    meta: {
      requiresAuth: true,
      prescriberFriendly: true,
      externalFriendly: true,
    },
  },

  {
    path: "table/:table_name",
    name: "ExternalsRecordsDashboard",
    component: () => import(/* webpackChunkName: "TablePage" */ "../views/TablePage.vue"),
    meta: {
      requiresAuth: true,
      externalFriendly: true,
    },
  },

  {
    path: "preview-record/:recordId?",
    name: "PreviewRecord",
    component: () => import(/* webpackChunkName: "DashBoard" */ "../views/PreviewRecord.vue"),
    meta: {
      requiresAuth: true,
      externalFriendly: true,
    },
  },

  {
    path: "reset-password/:resetToken?",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const childRoutePaths = childRoutes.map((route) => route.path);

const routes = [
  {
    path: "/",
    name: "root",
  },
  {
    path: "/:locale?",
    beforeEnter(to, from) {
      let locale = to.params.locale;

      if (languages.includes(locale)) {
        // IF VALID LOCALE
        if (store.state.locale !== locale) {
          store.dispatch("changeLocale", locale);
        }
        return true;
      }

      // FOR INVALID LOCALE FALLBACK TO STORE LOCALE
      return {
        name: to.name,
        params: { ...to.params, locale: store.state.locale },
      };
    },
    children: childRoutes,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    beforeEnter(to, from) {
      if (childRoutePaths.includes(to.fullPath.split("/")[1])) {
        return { path: store.state.locale + to.fullPath };
      }
      return true;
    },
    component: () => import(/* webpackChunkName: "error" */ "../views/Error.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

async function isLoggedIn() {
  // NO ACCESS TOKEN || UID || REFRESH TOKEN || MACHINE ID => LOGIN
  if (store.state.access_token == null || store.state.uid == null || store.state.refresh_token == null || store.state.auth_machine_id == null) {
    return false;
  }

  // ACCESS TOKEN, UID, REFRESH TOKEN AND MACHINE ID PRESENT
  else {
    const tokenValidData = {
      uid: store.state.uid,
      auth_machine_id: store.state.auth_machine_id,
      access_token: store.state.access_token,
    };

    const tokenValidReq = buildRequest(REQUESTS.POST, `${apiAuth}/is-access-token-valid`, tokenValidData);

    var authSuccess = await axios(tokenValidReq)
      .then((res) => {
        if (res.data.valid === true || res.data.valid === "true") {
          store.dispatch("changeUserPermissions", res.data.rights);
          return true;
        }
      })

      // NOT VALID ACCESS TOKEN => TRY REFRESH TOKEN
      .catch(async (err) => {
        console.log("Error checking access token.", err);

        // const refreshTokenData = {
        //   uid: store.state.uid,
        //   auth_machine_id: store.state.auth_machine_id,
        //   refresh_token: store.state.refresh_token,
        // };

        // const refreshTokenReq = buildRequest(
        //   REQUESTS.POST,
        //   `${apiAuth}/refresh-tokens`,
        //   refreshTokenData
        // );

        // var refreshSuccess = await axios(refreshTokenReq)
        //   .then((res) => {
        //     if (res.data.access_token) {
        //       store.dispatch("changeTokens", {
        //         access_token: res.data.access_token,
        //         refresh_token: res.data.refresh_token
        //       });
        //       store.dispatch("changeUserPermissions", res.data.rights);

        //       return true;
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("Error trying token refresh", err);
        //     return false;
        //   })

        return false;
      });

    return authSuccess;
  }
}

router.beforeEach(async (to, from) => {
  var loggedIn = await isLoggedIn();
  store.dispatch("changeLogInStatus", loggedIn);

  // CHECKS ROUTES THAT REQUIRE AUTH
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // LOGGED IN
    if (loggedIn) {
      // LOCK PRESCRIBER USERS TO PRESCRIBER USER RECORD PAGE
      if (store.state.userPermissions.user_is_prescriber) {
        // CHECK WHITELIST
        if (to.meta.prescriberFriendly) {
          return true;
        } else {
          if (to.name == "PrescribersDashboard") return true;
          else {
            return {
              name: "PrescribersDashboard",
              params: { table_name: "doctors", locale: store.state.locale },
            };
          }
        }
      }

      // LOCK EXTERNAL USERS TO CERTAIN PAGES
      if (store.state.userPermissions.user_external) {
        // CHECK WHITELIST
        if (to.meta.externalFriendly && (to.meta.externalFriendly === true || to.meta.externalFriendly.includes(to.params.table_name))) {
          return true;
        } else {
          if (to.name == "ExternalsRecordsDashboard") return true;
          else {
            return {
              name: "ExternalsRecordsDashboard",
              params: { table_name: "external_records", locale: store.state.locale },
            };
          }
        }
      }

      // ALL OTHER USERS
      else {
        return true;
      }
    }

    // NOT LOGGED IN => LOGIN PAGE
    else {
      store.dispatch("changeNextUrl", to.fullPath);
      return {
        name: "Login",
        params: { locale: store.state.locale },
      };
    }
  }

  // ROUTES THAT DON'T REQUIRE AUTH
  else {
    // LOGIN PAGE
    if (to.name == "Login") {
      // NOT LOGGED IN
      if (!loggedIn) {
        return true;
      }

      // LOGGED IN => DASHBOARD PAGE
      else {
        return { name: "Dashboard" };
      }
    }

    // NO LOGIN REQUIRED + NOT GOING TO LOGIN PAGE
    else {
      if (to.name == "root") {
        if (store.state.nextUrl) {
          // PROSANTE CONNECT REDIRECTION
          if (store.state.nextUrl.match(/^\/(fr|en)\/(security)?$/)) {
            // REDIRECT WITH PARAMETERS AND MAKE THE REQUEST OR DIRECTLY get user information
            let urlParams = new URLSearchParams(window.location.search);
            let state = urlParams.get('state');
            let session_state = urlParams.get('session_state');
            let code = urlParams.get('code');
            
            if (state && session_state && code) {
              // nextUrl is set to security page --> get userInformation and add to database
              if (store.state.nextUrl.match(/^\/(fr|en)\/security$/)) {
                console.log("Security page");
                store.dispatch("changeNextUrl", null);
                return {
                  name: "Security",
                  params: { locale: store.state.locale },
                  query: { state, session_state, code }
                };
              } else { // LOGIN
                store.dispatch("changeNextUrl", null);
                return {
                  name: "Login",
                  params: { locale: store.state.locale },
                  query: { state, session_state, code }
                };
                // connect the ninja user with his prosante uuid.
              }
            }
          }
        }

        return { name: "Dashboard", params: { locale: store.state.locale } };
      } else {
        return true;
      }
    }
  }
});

export default router;
