<template>
	<div id='App' class="d-flex flex-column flex-lg-row vh-100 vw-100">
		<NavBar v-if="$store.state.loggedIn" />
		<router-view class="router-view h-100 overflow-auto" />
		<vue-progress-bar></vue-progress-bar>
	</div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { mapState } from "vuex";
import {
  apiAuth,
  buildRequest,
  REQUESTS,
  errorHandler,
} from "./components/Javascript/methods";
import axios from "@/axiosClient";

export default {
  name: "app",
  components: {
    NavBar,
  },
  setup() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    const internalInstance = getCurrentInstance();
    internalInstance.appContext.config.globalProperties.$Progress.start();
    setTimeout(() => {
      internalInstance.appContext.config.globalProperties.$Progress.finish();
    }, 3500);

    const { t, te } = useI18n();
    const route = useRoute();
    return { t, te, route };
  },
  computed: mapState(["locale"]),
  watch: {
    locale() {
      this.$router.replace({ params: { locale: this.locale } }).catch(() => { });
    },
  },
  created() {
    this.$store.dispatch("changeLocale", this.$store.state.locale);
    document.documentElement.setAttribute('data-theme', this.$store.state.theme ? this.$store.state.theme : 'light');
    this.setupTimers();
  },
  data() {
    return {
			errorHandler,
      timeoutInMS: 3.6e6, // 1 Hour
      timeoutId: null,
    }
  },
  methods: {
    logout() {
			
			if (!this.$store.state.loggedIn) return;

      const logoutData = {
        refresh_token: this.$store.state.refresh_token,
      };

      const apiLogout = apiAuth + "/logout";

      const config = buildRequest(REQUESTS.PUT, apiLogout, logoutData, true);

      axios(config)
        .then(() => {
          this.$store.dispatch("changeTokens", { access_token: null, refresh_token: null });
          this.$store.dispatch("changeUid", null);
          this.$store.dispatch("changeMachineId", null);
          this.$store.dispatch("changeUserPermissions", {});
					this.$store.dispatch("changeLogInStatus", false);
					this.warningPop("misc.inactivityLogout", '', false);
          this.goToPage("");
        })
        .catch((error) => {
          this.errorHandler(error);
        });
    },

    startTimer() {
      // setTimeout returns an ID (can be used to start or clear a timer)
      this.timeoutId = setTimeout(this.logout, this.timeoutInMS);
    },

    resetTimer() {
      clearTimeout(this.timeoutId);
      this.startTimer();
    },

    setupTimers() {
      document.addEventListener("keypress", this.resetTimer, false);
      document.addEventListener("mousedown", this.resetTimer, false);
      document.addEventListener("touchmove", this.resetTimer, false);

      this.startTimer();
    },
  }
};
</script>

<style>
/* --------------------------------------------- THE BASICS ----------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#app {
	background: var(--background);
	color: var(--primary-text);
	font-family: "Montserrat", sans-serif;
	font-style: normal;
	scroll-behavior: smooth;
	font-size: 14px;
	font-optical-sizing: auto;
}

h1 {
	font-size: 24px !important;
	text-wrap: nowrap;
	font-weight: bold !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	text-transform: capitalize;
	color: var(--accent-1);
}

h2 {
	font-size: 18px !important;
	text-wrap: nowrap;
	font-weight: bold !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	text-transform: capitalize;
}

h3 {
	font-size: 16px !important;
	text-wrap: nowrap;
	font-weight: bold !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	text-transform: capitalize;
}

h4 {
	font-size: 16px !important;
}

/* Alert bubbles below input fields */
.alert {
	border-radius: 10px;
}

/* --------------------------------------------- CUSTOM ELEMENT BASE STYLE ----------------------------------------- */
/* ~~~~~~~~~~~~~~~~~~~~~~~~ BLOCK ~~~~~~~~~~~~~~~~~~~~~~~~ */

.slider {
	/* The slider */
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;

	&:before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	&.round {
		/* Rounded sliders */
		border-radius: 34px;

		&:before {
			border-radius: 50%;
		}
	}
}

input:checked+.slider {
	background-color: var(--accent-1);
}

input:focus+.slider {
	box-shadow: 0 0 1px var(--accent-1);
}

input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

input::file-selector-button {
	font-weight: bold;
	color: var(--accent-2);
	border: none;
	height: 100%;
	background-color: var(--input);
}

.blank-card {
	border-radius: 10px;
	box-shadow: var(--button-shadow);
	background-color: var(--card-bg);
}

.blank-card-modal {
	height: fit-content;
	max-width: 1200px;
	padding: 50px;
	margin: 3% auto;
}

.card-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.modalOn {
	padding: 10px 10px;
	display: block;
	position: fixed;
	z-index: 9000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.8);
}


.arrow-buttons {
	fill: var(--icon) !important;
	margin: 3.5px auto;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~ BUTTONS ~~~~~~~~~~~~~~~~~~~~~~~~ */
.closeModalButtons {
	position: absolute;
	top: 10px;
	right: 10px;
}

.offcanvas {
	background-color: var(--card-bg);
	color: var(--primary-text);
}

.search-button {
	height: 30px;
	background-color: white;
	border-radius: 50px;
	border-left: 2px solid #e2e2e2;
	color: var(--input-border);
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~ TEXT ~~~~~~~~~~~~~~~~~~~~~~~~ */
.forgot-password-text {
	font-weight: bold;
}

.error-message {
	color: var(--error-border);
	display: block;
}

span.error-message:first-letter {
	text-transform: uppercase;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~ OTHERS ~~~~~~~~~~~~~~~~~~~~~~~~ */
.loader {
	border: 10px solid #f5f5fb;
	border-top: 10px solid var(--accent-1);
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}


/* ~~~~~~~~~~~~~~~~~~~~~~~~ TABLE ~~~~~~~~~~~~~~~~~~~~~~~~ */
.custom-table {
	background-color: var(--card-bg);
	height: 100%;
	border: var(--input-border) 1px solid;
	border-radius: 0px;
	box-shadow: var(--button-shadow);
	padding-bottom: 0;
	overflow-x: auto;
	overflow-y: auto;

	& th::after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 5px;
		/* zone de saisie */
		cursor: ew-resize;
		/* icône du curseur pour le redimensionnement horizontal */
		z-index: 2;
	}

	& thead {
		background-color: var(--card-bg);
		filter: brightness(90%);
		position: sticky;
		top: 0;
		z-index: 1;
	}

	& tr {
		transition: ease-in-out 0.2s;
		border-color: var(--input-border) !important;
		border-bottom: 1px solid;
	}

	& tbody>tr:hover {
		background-color: var(--card-bg);
		filter: brightness(90%);
	}
}

.table-header {
	background-color: var(--card-bg);
	filter: brightness(90%);
	border: var(--input-border) 1px solid;
	border-radius: 15px 15px 15px 15px;
}

/* --------------------------------------------- END STYLE ----------------------------------------- */

/* A RETIRER */

label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

textarea {
	padding: 10px 9px !important;
	border-radius: 10px;
	border: 2px solid var(--input-border);
	display: block;
	width: 100%;
	height: 150px;
	color: var(--primary-text);
	background-color: var(--input);

	&:disabled {
		background-color: var(--disabled);
		color: var(--disabled-text);
		cursor: not-allowed;
		font-weight: 600;
	}

	&:focus {
		outline: none;
	}
}

input {
	padding: 0px 9px !important;
	border-radius: 10px;
	height: 49px;
	border: 2px solid var(--input-border);
	background-color: var(--input);
	display: block;
	width: 100%;

	&:disabled {
		background-color: var(--disabled);
		color: var(--disabled-text);
		cursor: not-allowed;
		font-weight: 600;
	}

	&:focus {
		outline: none;
	}
}

.lines-per-page-dropdown {
	background-color: var(--accent-1);
	color: var(--icon);
	border-radius: 25px;
	box-shadow: var(--button-shadow);
	text-align: end;
}

/* FIN */


::-webkit-scrollbar {
	width: 5px;
	height: 6px;
}

/* ::-webkit-scrollbar-track {
	margin-top: 0px;
	border-radius: 0px;
	background: transparent;
} */

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--accent-1);
	border-radius: 0px;
	transition: ease-in-out .2s;
	filter: saturate(100%);

	&:hover {
		filter: saturate(200%);
	}
}

::-webkit-scrollbar-button {
	width: 0px;
	height: 5px;
}

.v-select {
	--vs-font-size: 14px !important;
	--vs-border-color: var(--input-border);
	--vs-border-width: 2px;
	--vs-border-radius: 10px;
	--vs-line-height: 33px;

	--vs-state-disabled-bg: var(--disabled);
	--vs-state-disabled-color: var(--disabled-text);
	--vs-state-disabled-controls-color: var(--disabled);
	--vs-state-disabled-cursor: not-allowed;
	--vs-controls-color: var(--accent-1);

	--vs-dropdown-bg: var(--input);
	--vs-dropdown-color: var(--primary-text);
	--vs-dropdown-z-index: 9999;
	--vs-dropdown-min-width: 160px;
	--vs-dropdown-max-height: 350px;
	--vs-dropdown-box-shadow: var(--button-shadow);
	--vs-disabled-bg: var(--disabled);

	--vs-dropdown-option-bg: var(--input);
	--vs-dropdown-option-color: var(--primary-text);
	--vs-dropdown-option-padding: 3px 20px;
	--vs-dropdown-option--active-bg: var(--accent-1);
	--vs-dropdown-option--active-color: #fff;

	--vs-selected-bg: var(--disabled);
	--vs-selected-color: var(--primary-text);
	--vs-selected-border-color: var(--input-border);
	--vs-selected-border-width: var(--vs-border-width);

	--vs-search-input-color: var(--primary-text);
	--vs-search-input-bg: var(--input);
	--vs-search-input-placeholder-color: var(--input-border);

	--vs-actions-padding: 4px 20px 4px 20px;
}

.vs--single .vs__selected-options {
	flex-wrap: nowrap !important;
}

.vs__dropdown-menu {
	font-size: 14px !important;
}

.vs__selected {
	font-size: 14px !important;
}

.vs__dropdown-toggle,
.vs__dropdown-menu {
	background-color: var(--input) !important;
}

.vs__dropdown-toggle {
	border: 2px solid var(--input-border) !important;
	min-height: 49px;
}

input.vs__search {
	height: auto !important;
}

input.vs__search.vs--disabled {
	background-color: none;
}

.input-field-tall {
	border-radius: 10px;
	border: 2px solid var(--input-border);
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: var(--input);
	min-height: 150px;
}


@media (min-width: 575px) {

	h1 {
		font-size: 30px !important;
	}

	h2 {
		font-size: 24px !important;
	}

	h3 {
		font-size: 20px !important;
	}

	h4 {
		font-size: 18px !important;
	}
}


input::-webkit-input-placeholder {
	color: var(--input-border);
}

/* Firefox < 19 */
input:-moz-placeholder {
	color: var(--input-border);
}

/* Firefox > 19 */
input::-moz-placeholder {
	color: var(--input-border);
}

/* Internet Explorer 10 */
input:-ms-input-placeholder {
	color: var(--input-border);
}

.form-switch {
	display: flex;
	align-items: center;
}

.form-switch {

	.form-check-input {
		width: 3.5em !important;
		height: 2em !important;
		margin-right: 10px;
		margin-top: 0px;
		margin-bottom: 0px;
		vertical-align: bottom;
	}
}

.form-check-input {
	width: 2em !important;
	height: 2em !important;
}

.nav-link {
	color: var(--accent-1);
}

.nav:hover {
	--bs-nav-link-hover-color: var(--accent-1);
}
</style>
